<template>
  <div>
    <b-card no-body>
      <b-tabs card class="d-block nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x custom-nav">
        <b-tab :title="$t('navigation.bank_transactions')" class="bg-secondary-o-50 pt-4" active>
          <TransactionsList/>
        </b-tab>
        <b-tab :title="$t('navigation.contract_payment_schedule')" class="bg-secondary-o-50 pt-4">
          <PaymentsList/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import TransactionsList from './TransactionsList';
import PaymentsList from './PaymentsList';

export default {
  components: {
    TransactionsList,
    PaymentsList
  },
  methods: {
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll('.nav-link');
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove('active');
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute('data-tab'));

      // set current active tab
      event.target.classList.add('active');
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-nav::v-deep {
  .nav-item {
    margin-bottom: 0 !important;
    padding-left: 15px;
  }
}
</style>